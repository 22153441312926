import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'mspot-portal-footer',
  template: `<ng-content></ng-content>`,
  host: {
    class: 'flex flex-shrink-0 p-4 border-t border-gray-200',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PortalFooterComponent {}

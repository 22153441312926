import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';

import { PortalContentComponent } from './portal-content/portal-content.component';
import { PortalFooterComponent } from './portal-footer/portal-footer.component';
import { PortalFormWrapperComponent } from './portal-form-wrapper/portal-form-wrapper.component';
import { PortalLoaderComponent } from './portal-loader/portal-loader.component';

@NgModule({
  imports: [CommonModule, PortalModule, RouterModule, TranslocoModule],
  declarations: [
    PortalLoaderComponent,
    PortalFormWrapperComponent,
    PortalContentComponent,
    PortalFooterComponent,
  ],
  exports: [
    PortalLoaderComponent,
    PortalFormWrapperComponent,
    PortalContentComponent,
    PortalFooterComponent,
  ],
})
export class FrontendSharedUiPortalLoaderModule {}

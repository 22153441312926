import { CdkPortalOutlet, ComponentPortal } from '@angular/cdk/portal';
import {
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { PortalSidebarComponent } from '@memberspot/frontend/shared/ui/sidebar-layout';
import { BodyService } from '@memberspot/frontend/shared/util/document';

import { PortalLoaderService } from '../portal-loader.service';

@Component({
  selector: 'mspot-portal-loader',
  templateUrl: './portal-loader.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PortalLoaderComponent implements OnInit {
  @ViewChild(CdkPortalOutlet, { static: true }) _portalOutlet!: CdkPortalOutlet;

  constructor(
    private portalLoaderService: PortalLoaderService,
    private drawer: PortalSidebarComponent,
    private bodyService: BodyService,
  ) {}

  ngOnInit(): void {
    this.portalLoaderService.registerPortal(this, this.drawer);
  }

  attachComponentPortal<T>(portal: ComponentPortal<T>): ComponentRef<T> {
    if (this._portalOutlet.hasAttached()) {
      throw new Error('Portal already attached');
    }

    this.bodyService.togglePortalOpenClass('add');

    return this._portalOutlet.attachComponentPortal(portal);
  }

  dettachComponentPortal() {
    this.bodyService.togglePortalOpenClass('remove');

    return this._portalOutlet.detach();
  }
}
